.header {
  z-index: 1002;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}

.menu-list {
  border-bottom: 1px solid #414750; // grey-650
}
