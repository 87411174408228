//Partials
@import '~@amzn/awsui-design-tokens/polaris.scss';

html {
	scroll-behavior: smooth;
}

body {
	margin: 0;
	padding: 0;
	background-color: $color-background-layout-main	
	;

	// HELPERS
	.pull {
		&-right { float: right; }
		&-left { float: left; }
	}

	.align {
		&-right { text-align: right; }
		&-left { text-align: left; }
		&-center { text-align: center; }
	}

	.color {
		&-primary {
			color: $color-text-accent;
		}
	}

	.small-font {
		font-size: .8em;
	}
}